import { Table } from "./components/Table";
import { Home } from "./components/Home";
import { DoughnutChart } from "./components/DoughnutChart";
import {CheckInTable} from "./components/CheckInTable";
import {CheckInProgress} from "./components/CheckInProgress";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/table',
    element: <Table />
  },
  {
    path: '/chart',
    element: <DoughnutChart isMobile={false}/>
  },
  {
    path: '/checkin-progress',
    element: <CheckInProgress isMobile={false} />
  },
  {
    path: '/checkin-progress-mobile',
    element: <CheckInProgress isMobile={true}/>
  },
  {
    path: '/checkin-table',
    element: <CheckInTable />
  },
  {
    path: '/chart-mobile',
    element: <DoughnutChart isMobile={true} />
  }
];

export default AppRoutes;
