import React, { useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { stringify } from "csv-stringify/browser/esm/sync";
import { Container } from 'reactstrap';

export function Table() {
  const pageSize = 10;
  const currYear = new Date().getFullYear();

  const SortBy = {
    None: 0,
    CourseTitle: 1,
    EnrolmentDate: 2,
    Status: 3,
    PskHours: 4,
    CompletionDate: 5
  }

  const SortDirection = {
    Asc: 0,
    Desc: 1
  }
  
  const [loading, setLoading] = useState(true);
  const [allEnrollments, setAllEnrollments] = useState([]);
  const [filteredEnrollments, setFilteredEnrollments] = useState([]);
  const [enrollmentsPage, setEnrollmentsPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPskHours, setTotalPskHours] = useState(0);
  const [minYear, setMinYear] = useState(currYear);
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Sorting
  const [sortDirection, setSortDirection] = useState(SortDirection.Asc);
  const [sortBy, setSortBy] = useState(SortBy.None);
  
  // Filters
  const [selectedYear, setSelectedYear] = useState(currYear);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [searchText, setSearchText] = useState('');
  
  // Populate data on page load
  useEffect(() => {
    populateData();
  }, []);
  
  async function populateData() {
    let enrollments = await (await fetch('EnrollmentData/Enrollments?' + searchParams)).json();
    setAllEnrollments(enrollments);

    setMinYear(Math.min.apply(null, enrollments.map(enrollment => parseInt(enrollment.completionDate)).filter(d => !isNaN(d))));
    
    let filteredEnrollments = FilterEnrollments(enrollments, selectedYear, searchText, selectedStatus, sortBy, sortDirection);
    setFilteredEnrollments(filteredEnrollments);
    setCurrentPage(1);

    setLoading(false);
  }
  
  // Filters & sorting
  
  function Compare(val1, val2, sortDirection) {
    if (val1 < val2) return sortDirection === SortDirection.Asc ? -1 : 1;
    if (val1 > val2) return sortDirection === SortDirection.Asc ? 1 : -1;
    return 0;
  }
  
  function CompareString(s1, s2, sortDirection) {
    return Compare(s1.toLowerCase(), s2.toLowerCase(), sortDirection);
  }

  function CompareDate(d1, d2, sortDirection) {
    return Compare(
        new Date(d1 ? d1 : "1900-01-01T00:00:00"), 
        new Date(d2 ? d2 : "1900-01-01T00:00:00"),
        sortDirection);
  }

  function FilterEnrollments(enrollments, year, search, status, sort, sortDir) {
    // Filter by selected year
    let filteredEnrollments = enrollments.filter(enrollment =>
        year.toString() === new Date(enrollment.completionDate).getFullYear().toString() 
        || (year.toString() === currYear.toString() && enrollment.completionDate == null));

    // Filter by search text
    if (search && search.length > 0)
      filteredEnrollments = filteredEnrollments.filter(enrollment =>
          enrollment.courseName.toLowerCase().includes(search.toLowerCase()))

    // Filter by enrollment status
    if (status !== "all")
      filteredEnrollments = filteredEnrollments.filter(enrollment => enrollment.status === status);
    
    // Get psk hours for completed filtered enrollments
    let totalPskHours = filteredEnrollments
        .filter(enrollment => enrollment.status === "completed")
        .reduce((hours, enrollment) => hours + enrollment.credits, 0)
        .toFixed(1);
     
    // Remove _.0 at end of value
    setTotalPskHours(totalPskHours.endsWith('.0') ? totalPskHours.slice(0, totalPskHours.length - 2) : totalPskHours);
    
    // Sort filtered enrollments
    switch (sort) {
      case SortBy.None:
        return filteredEnrollments;

      case SortBy.CourseTitle:
        return filteredEnrollments.sort((a,b) => 
        { return CompareString(a.courseName, b.courseName, sortDir); });

      case SortBy.Status:
        return filteredEnrollments.sort((a,b) => 
        { return CompareString(a.status, b.status, sortDir); });

      case SortBy.PskHours:
        return filteredEnrollments.sort((a,b) => 
        { return Compare(a.credits, b.credits, sortDir); });

      case SortBy.EnrolmentDate:
        return filteredEnrollments.sort((a,b) => 
        { return CompareDate(a.enrollmentDate, b.enrollmentDate, sortDir); });

      case SortBy.CompletionDate:
        return filteredEnrollments.sort((a,b) => 
        { return CompareDate(a.completionDate, b.completionDate, sortDir); });
    }
  }

  // Filter & sorting setters
  
  function SetSelectedYear(event) {
    setSelectedYear(event.target.value);
    let filteredEnrollments = FilterEnrollments(allEnrollments, event.target.value, searchText, selectedStatus, sortBy, sortDirection);
    setFilteredEnrollments(filteredEnrollments);
    setCurrentPage(1);
  }
  
  function SetSelectedStatus(event) {
    setSelectedStatus(event.target.value);
    let filteredEnrollments = FilterEnrollments(allEnrollments, selectedYear, searchText, event.target.value, sortBy, sortDirection);
    setFilteredEnrollments(filteredEnrollments);
    setCurrentPage(1);
  }
  
  function SetSearchText(event) {
    setSearchText(event.target.value);
    let filteredEnrollments = FilterEnrollments(allEnrollments, selectedYear, event.target.value, selectedStatus, sortBy, sortDirection);
    setFilteredEnrollments(filteredEnrollments);
    setCurrentPage(1);
  }

  function SetSortBy(newSortBy) {
    let newSortDirection = SortDirection.Asc;
    if (sortBy === newSortBy)
      newSortDirection = sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    
    setSortBy(newSortBy);
    setSortDirection(newSortDirection);

    let filteredEnrollments = FilterEnrollments(allEnrollments, selectedYear, searchText, selectedStatus, newSortBy, newSortDirection);
    setFilteredEnrollments(filteredEnrollments);
    setCurrentPage(currentPage);
  }
  
  // Misc helper functions

  function FormatStatus(status) {
    switch (status) {
      case "enrolled": return "Enrolled";
      case "in_progress": return "In Progress";
      case "completed": return "Completed";
    }
  }

  function FormatDate(date) {
    return date ? new Date(date).toLocaleDateString() : null;
  }

  function FormatTime(timeSeconds) {
    let hours = Math.floor(timeSeconds / 3600);
    let minutes = Math.floor((timeSeconds % 3600) / 60);
    let seconds = timeSeconds % 60;

    return `${hours > 0 ? hours + 'h ' : ''}${hours + minutes > 0 ? minutes + 'm ' : ''}${seconds}s`
  }

  function Range(min, max) {
    return Array.from({length: max - min + 1}, (x, i) => min + i);
  }
  
  function PageCount() {
    return Math.ceil(filteredEnrollments.length / pageSize);
  }
  
  function SortIcon(sortType) {
    if (sortType !== sortBy)
      return <FontAwesomeIcon icon={faSort} className="sort-icon"/>;
    
    return sortDirection === SortDirection.Asc
      ? <FontAwesomeIcon icon={faSortUp} className="sort-icon"/>
      : <FontAwesomeIcon icon={faSortDown} className="sort-icon"/>;
  }
  
  function ExportAsCsv() {
    // Format data
    const data = stringify(filteredEnrollments.map(enrollment => { return {
      courseCode: enrollment.courseCode,
      courseName: enrollment.courseName,
      status: FormatStatus(enrollment.status),
      enrollmentDate: FormatDate(enrollment.enrollmentDate),
      expiresAt: FormatDate(enrollment.expirationTime),
      completionDate: FormatDate(enrollment.completionDate),
      credits: enrollment.credits,
      timeSpent: FormatTime(enrollment.timeSpent),
      score: enrollment.score,
    }}), 
    { 
      header: true,
      columns: {
        courseCode: "Course code",
        courseName: "Course name",
        status: "User status",
        enrollmentDate: "Enrolled on",
        expiresAt: "Expiration date",
        completionDate: "Completed on",
        credits: "PSK hours",
        timeSpent: "Total time",
        score: "Score"
      }
    })
    
    // Generate download
    const blob = new Blob([data], { type: 'text/csv' });
    const a = document.createElement('a');
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', 'enrollments.csv');
    a.click();
  }
  
  return (
    <Container>
      <div className="py-3">
        <div className="row">
          <div className="col-12">
            <h4>Filters</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="input-group">
              <span className="input-group-text">Year</span>
              <select className="form-select" defaultValue={currYear} onChange={SetSelectedYear}>
                {Range(minYear, currYear).reverse().map(year => <option value={year} key={year}>{year}</option>)}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <span className="input-group-text">Status</span>
              <select className="form-select" defaultValue="all" onChange={SetSelectedStatus}>
                <option value="all">All</option>
                <option value="enrolled">Enrolled</option>
                <option value="in_progress">In Progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <span className="input-group-text">Search</span>
              <input type="text" className="form-control" placeholder="Course title" aria-label="Search" onChange={SetSearchText}/>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex justify-content-end">
              {filteredEnrollments.length === 0 ? <></> :
                <button className="btn btn-outline-secondary" onClick={ExportAsCsv}>Export as CSV</button>}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th colSpan="9"><h4>Course Enrolments</h4></th>
            </tr>
            <tr>
              <th scope="col"><span>COURSE CODE</span></th>
              <th scope="col" className="sortable" onClick={e => SetSortBy(SortBy.CourseTitle)}><span>COURSE NAME{SortIcon(SortBy.CourseTitle)}</span></th>
              <th scope="col" className="sortable" onClick={e => SetSortBy(SortBy.Status)}><span>USER STATUS{SortIcon(SortBy.Status)}</span></th>
              <th scope="col" className="sortable" onClick={e => SetSortBy(SortBy.EnrolmentDate)}><span>ENROLLED ON{SortIcon(SortBy.EnrolmentDate)}</span></th>
              <th scope="col"><span>EXPIRATION DATE</span></th>
              <th scope="col" className="sortable" onClick={e => SetSortBy(SortBy.CompletionDate)}><span>COMPLETED ON{SortIcon(SortBy.CompletionDate)}</span></th>
              <th scope="col" className="sortable" onClick={e => SetSortBy(SortBy.PskHours)}><span>PSK HOURS{SortIcon(SortBy.PskHours)}</span></th>
              <th scope="col"><span>TOTAL TIME</span></th>
              <th scope="col"><span>SCORE</span></th>
            </tr>
          </thead>
          <tbody>
            {loading 
              ? <tr><td colSpan="9"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>
              :filteredEnrollments.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(enrollment => 
                <tr key={enrollment.courseId}>
                  <td>{enrollment.courseCode}</td>
                  <td>{enrollment.courseName}</td>
                  <td>{FormatStatus(enrollment.status)}</td>
                  <td>{FormatDate(enrollment.enrollmentDate)}</td>
                  <td>{FormatDate(enrollment.expirationTime)}</td>
                  <td>{FormatDate(enrollment.completionDate)}</td>
                  <td>{enrollment.credits}</td>
                  <td>{FormatTime(enrollment.timeSpent)}</td>
                  <td>{enrollment.score}</td>
                </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="6" className="text-right">TOTAL COMPLETED PSK LEARNING HOURS FOR SELECTED COURSES:</th>
              <th className="text-center">{totalPskHours}</th>
              <th colSpan="2">&nbsp;</th>
            </tr>
          </tfoot>
        </table>
        <div className="mb-2">Displaying {filteredEnrollments.length} results</div>
        <div id="pagination-cell">
          <ul className="pagination justify-content-center">
            <li className={currentPage === 1 ? "page-item disabled" : "page-item"} key={0}>
              <button className="page-link" aria-label="Previous" onClick={e => setCurrentPage(currentPage - 1)}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {Range(1, PageCount()).map(page => 
                <li className="page-item" key={page}><button className="page-link" onClick={e => setCurrentPage(page)}>{page}</button></li>)}
            <li className={currentPage === PageCount() ? "page-item disabled" : "page-item"} key={-1}>
              <button className="page-link" aria-label="Next" onClick={e => setCurrentPage(currentPage + 1)}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
}