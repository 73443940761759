import { Chart as ChartJs, ArcElement, Tooltip, Title, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CheckInProgress } from "./CheckInProgress";

ChartJs.register(ArcElement, Tooltip, Title, Legend);

export function DoughnutChart({ isMobile }) {
    const currYear = new Date().getFullYear();
    const [completedHours, setCompletedHours] = useState(0);
    const [requiredHours, setRequiredHours] = useState(0);
    const [checkInHours, setCheckInHours] = useState(0);
    const [extraHours, setExtraHours] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getData();
    }, []);
    
    async function getData(){
        let requiredHours = await (await fetch('EnrollmentData/RequiredPskHours?' + searchParams)).json();
        let checkInHours = await (await fetch('CheckIn/currentYear?' + searchParams)).json();
        setCheckInHours(checkInHours);
        setCompletedHours(requiredHours.completed);
        setRequiredHours(Math.max(0, requiredHours.required - checkInHours - requiredHours.completed));
        setExtraHours(requiredHours.completed- requiredHours.required)
    }
    
    let data = {
        labels: [
            'Achieved',
            'Yet to achieve',
            'Check-in Hours Earned'
        ],
        datasets: [
            {
                label: 'Current year PSK Learning Hours',
                data: [
                    completedHours === 0 ? 0.0001 : completedHours,
                    requiredHours,
                    checkInHours
                ],
                backgroundColor: [
                    'rgb(0,188,215)',
                    'rgb(235,30,99)',
                    'rgb(227,160,35)',
                ],
                borderColor: [
                    'rgb(255, 255, 255)',
                    'rgb(255, 255, 255)',                 
                    'rgb(255, 255, 255)',                 
                ],
                borderWidth: 1,
                hoverOffset: 4,
                extraHours: extraHours
            }
        ]
    };

    let doughnutLabelsLine = {
        id: 'doughnutLabelsLine',
        afterDraw(chart, args, options){
            let  { ctx, chartArea: { top, bottom, left, right, width, height } } = chart;
            
            chart.data.datasets.forEach((dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                    const { x, y } = datapoint.tooltipPosition(false);
                    
                    let value = chart.data.datasets[i].data[index];
                    
                    if (value === 0)
                        return;
                    
                    if (index === 2) // Check in hours
                        value = value.toFixed(3);
                    else {
                        value = value.toFixed(1);
                    
                        // Remove _.0 at end of value
                        if (value.endsWith('.0'))
                            value = value.slice(0, value.length - 2)
                    }

                    const halfWidth = width / 2;
                    const halfHeight = height / 2;

                    const xLine = x  >= halfWidth ? x + 15 : x - 15;
                    const yLine = y >= halfHeight ? y + 15 : y - 15;
                    const extraLine = x >= halfWidth ? 50 : -50;

                    ctx.beginPath();
                    ctx.moveTo(x, y);
                    ctx.lineTo(xLine, yLine);
                    ctx.lineTo(xLine + extraLine, yLine);
                    ctx.strokeStyle = 'rgb(102,102,102)';
                    ctx.stroke();

                    //text
                    ctx.font = '14px Arial';

                    // control the position
                    const textXPosition = x >= halfWidth ? 'left' : 'right';
                    const plusPx = x >= halfWidth ? 5 : -5;
                    ctx.textAlign = textXPosition;
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = 'rgb(102,102,102)';
                    ctx.fillText(`${value} hours`, xLine + extraLine + plusPx, yLine);
                })
            })
            
            if (chart.data.datasets[0].extraHours > 0) {
                // center text
                const xPos = chart.getDatasetMeta(0).data[0].x;
                const yPos = chart.getDatasetMeta(0).data[0].y;
                        
                ctx.font = "12px Arial";
                
                let value = chart.data.datasets[0].extraHours.toFixed(1);
                // Remove _.0 at end of value
                if (value.endsWith('.0'))
                    value = value.slice(0, value.length - 2)
                
                let centerTextHeading = "SELF LEARNING";
                let centerText = `${value} hours`;
    
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = 'rgb(102,102,102)';
                ctx.fillText(centerTextHeading, xPos, yPos - 7);
                ctx.fillText(centerText, xPos, yPos + 7);
            }
        }
    };

    let options = {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
            title : {
                display: true,
                text: 'PSK Learning Hours (current year)',
                font: {
                    size: 20,
                    style: 'normal',
                    weight: 'normal'
                }
            },
            legend: {
                display: true,
                reverse: true,
                position: 'top',
                labels: {
                    padding: 25
                }
            }
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-center">
                <Doughnut data={data} options={options} plugins={[doughnutLabelsLine]} width={400} height={330} />
            </div> 
            <br />
            <div className="d-flex justify-content-center">
                <a className="psk_widget_button" target="_parent" href={isMobile 
                       ? "golearn://pages/66?domain=cGVyc29sa2VsbHkuZG9jZWJvc2Fhcy5jb20=" 
                       : "https://persolkelly.docebosaas.com/pages/63/psk-widget-table"}>
                       Click here for more information</a>
            </div>
        </div>
    );
}