import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export function CheckInProgress({ isMobile }) {
    const status = {
        Loading: 0,
        NotCheckedIn: 1,
        CheckedIn: 2,
        Error: 3
    }
    
    const [pointsToday, setPointsToday] = useState(0);
    const [pointsWeek, setPointsWeek] = useState(0.0);
    const [checkInStatus, setCheckInStatus] = useState(status.Loading);
    const [searchParams, setSearchParams] = useSearchParams();
    const maxPointsPerWeek = 30.0;

    useEffect(() => {
        getData();
    }, []);

    async function getData(){
        const d = new Date();
        const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        const url = `CheckIn?date=${date}&${searchParams}`;
        let checkInStatus = await (await fetch(url)).json();
        setPointsWeek(checkInStatus.currentWeekPoints);
        setCheckInStatus(checkInStatus.hasCheckedInToday ? status.CheckedIn : status.NotCheckedIn);
        setPointsToday(checkInStatus.checkInPoints);
    }

    async function checkIn(e) {
        if (checkInStatus === status.CheckedIn)
            return;
        
        if (document.body.animate) {
            let x = e.clientX;
            let y = e.clientY;

            if (e.clientX === 0 && e.clientY === 0) {
                const bbox = document.querySelector('#check-in-button-1').getBoundingClientRect();
                x = bbox.left + bbox.width / 2;
                y = bbox.top + bbox.height / 2;
            }

            const particle = document.createElement('particle');
            document.body.appendChild(particle);
            particle.textContent = `+${pointsToday}`

            // Store the animation in a variable as we will need it later
            const animation = particle.animate([
                {
                    // Set the origin position of the particle
                    // We offset the particle with half its size to center it around the mouse
                    transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
                    opacity: 1
                },
                {
                    // We define the final coordinates as the second keyframe
                    transform: `translate(${x}px, ${y - 50}px)`,
                    opacity: 0
                }
            ], {
                duration: 1000,
                easing: 'linear',
            });

            // When the animation is complete, remove the element from the DOM
            animation.onfinish = () => {
                particle.remove();
            };

            const d = new Date();
            const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
            const response = await fetch(`CheckIn?date=${date}&${searchParams}`, {method: "post"});

            if (response.ok){
                setCheckInStatus(status.CheckedIn);
                setPointsWeek(pointsWeek + pointsToday);
            }
            else {
                setCheckInStatus(status.Error);
                await new Promise(res => setTimeout(res, 1500));
                setCheckInStatus(status.NotCheckedIn);
            }
        }
    }
    
    function checkInButton() {
        let className;
        let onClick = null;
        let content;

        switch (checkInStatus) {
            case status.Loading:
                className = "check-in-button"
                content = <div className="spinner-border spinner-border-sm" role="status"></div>
                break;
            case status.NotCheckedIn:
                className = "check-in-button check-in-button-enabled"
                onClick = checkIn;
                content = "Check in"
                break;
            case status.CheckedIn:
                className = "check-in-button"
                content = "Checked in"
                break;
            case status.Error:
                className = "check-in-button check-in-button-error"
                content = "Error"
                break;
        }

        return (
            <button className={className} role="button" onClick={onClick}>
                {content}
            </button>
        )
    }


    return (
        <div className="d-flex justify-content-center">
            <div className="d-flex flex-column flex-grow-1 pt-3">
                <div className="d-flex justify-content-center py-2">
                    {checkInButton()}
                </div>
                <div className="d-flex flex-column p-3">
                    <span>Points this week</span>
                    <div className="d-flex flex-row position-relative">
                        <div className="progress_number">0</div>
                        <div className="progress_section section_1"></div>
                        <div className="progress_number">5</div>
                        <div className="progress_section section_2"></div>
                        <div className="progress_number">10</div>
                        <div className="progress_section section_3"></div>
                        <div className="progress_number">15</div>
                        <div className="progress_section section_4"></div>
                        <div className="progress_number">20</div>
                        <div className="progress_section section_5"></div>
                        <div className="progress_number">25</div>
                        <div className="progress_section section_6"></div>
                        <div className="progress_number">30</div>
                        <div className="progress_star_container"
                             style={{left: (Math.min(pointsWeek / maxPointsPerWeek, 1)) * 100 + '%'}}>
                            <svg className="progress_star" width="25px" height="25px" viewBox="0 0 24 24" fill="#000000"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.2691 4.41115C11.5006 3.89177 11.6164 3.63208 11.7776 3.55211C11.9176 3.48263 12.082 3.48263 12.222 3.55211C12.3832 3.63208 12.499 3.89177 12.7305 4.41115L14.5745 8.54808C14.643 8.70162 14.6772 8.77839 14.7302 8.83718C14.777 8.8892 14.8343 8.93081 14.8982 8.95929C14.9705 8.99149 15.0541 9.00031 15.2213 9.01795L19.7256 9.49336C20.2911 9.55304 20.5738 9.58288 20.6997 9.71147C20.809 9.82316 20.8598 9.97956 20.837 10.1342C20.8108 10.3122 20.5996 10.5025 20.1772 10.8832L16.8125 13.9154C16.6877 14.0279 16.6252 14.0842 16.5857 14.1527C16.5507 14.2134 16.5288 14.2807 16.5215 14.3503C16.5132 14.429 16.5306 14.5112 16.5655 14.6757L17.5053 19.1064C17.6233 19.6627 17.6823 19.9408 17.5989 20.1002C17.5264 20.2388 17.3934 20.3354 17.2393 20.3615C17.0619 20.3915 16.8156 20.2495 16.323 19.9654L12.3995 17.7024C12.2539 17.6184 12.1811 17.5765 12.1037 17.56C12.0352 17.5455 11.9644 17.5455 11.8959 17.56C11.8185 17.5765 11.7457 17.6184 11.6001 17.7024L7.67662 19.9654C7.18404 20.2495 6.93775 20.3915 6.76034 20.3615C6.60623 20.3354 6.47319 20.2388 6.40075 20.1002C6.31736 19.9408 6.37635 19.6627 6.49434 19.1064L7.4341 14.6757C7.46898 14.5112 7.48642 14.429 7.47814 14.3503C7.47081 14.2807 7.44894 14.2134 7.41394 14.1527C7.37439 14.0842 7.31195 14.0279 7.18708 13.9154L3.82246 10.8832C3.40005 10.5025 3.18884 10.3122 3.16258 10.1342C3.13978 9.97956 3.19059 9.82316 3.29993 9.71147C3.42581 9.58288 3.70856 9.55304 4.27406 9.49336L8.77835 9.01795C8.94553 9.00031 9.02911 8.99149 9.10139 8.95929C9.16534 8.93081 9.2226 8.8892 9.26946 8.83718C9.32241 8.77839 9.35663 8.70162 9.42508 8.54808L11.2691 4.41115Z"
                                    stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <a className="psk_widget_button" target="_parent" href={isMobile
                        ? "golearn://pages/79?domain=cGVyc29sa2VsbHkuZG9jZWJvc2Fhcy5jb20=" 
                        : "https://persolkelly.docebosaas.com/pages/79/checkin-widget-table"}>
                        View check-in history</a>
                </div>
            </div>
        </div>
    );
}