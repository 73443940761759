import React, { useEffect, useState} from 'react';
import { stringify } from "csv-stringify/browser/esm/sync";
import { Container } from 'reactstrap';
import {useSearchParams} from "react-router-dom";

export function CheckInTable() {
  const pageSize = 10;
  const currYear = new Date().getFullYear();
  
  const [loading, setLoading] = useState(true);
  const [checkInWeeks, setCheckInWeeks] = useState(new Map());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalHours, setTotalHours] = useState(0);
  const [minYear, setMinYear] = useState(currYear);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedYear, setSelectedYear] = useState(currYear);
  
  // Populate data on page load
  useEffect(() => {
    populateData(currYear);
  }, []);
  
  async function populateData(year) {
    const url = `CheckIn/year?year=${year}&${searchParams}`;
    let checkInsYear = await (await fetch(url)).json();
    setTotalHours(Object.values(checkInsYear.weeks).reduce((sum, val) => sum + val.total_hours, 0).toFixed(3))
    setMinYear(checkInsYear.minYear);
    setCheckInWeeks(new Map(Object.entries(checkInsYear.weeks)));
    setCurrentPage(1);
    setLoading(false);
  }

  // Filter & sorting setters
  function SetSelectedYear(event) {
    setLoading(true);
    setSelectedYear(event.target.value);
    populateData(event.target.value);
  }
  
  // Misc helper functions
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  
  function FormatDate(date) {
    date = new Date(date)
    return `${date.getDate()} ${month[date.getMonth()]}`
  }

  function Range(min, max) {
    return Array.from({length: max - min + 1}, (x, i) => min + i);
  }
  
  function PageCount() {    
    return Math.ceil(checkInWeeks.size / pageSize);
  }

  function ExportAsCsv() {
    // Format data
    const data = stringify(checkInWeeks.keys().map(weekNum => { 
      const week = checkInWeeks.get(weekNum); 
      return {
        weekNum: weekNum,
        weekOfYear: `${FormatDate(week.week_date_start)} - ${FormatDate(week.week_date_end)}`,
        totalCheckIns: week.check_in_count,
        totalPoints: week.total_points,
        totalHours: week.total_hours.toFixed(3)
      }
    }), 
    { 
      header: true,
      columns: {
        weekNum: "Week number",
        weekOfYear: "Week",
        totalCheckIns: "Total check-ins",
        totalPoints: "Total points",
        totalHours: "Total hours",
      }
    })
    
    // Generate download
    const blob = new Blob([data], { type: 'text/csv' });
    const a = document.createElement('a');
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', `checkins-${selectedYear}.csv`);
    a.click();
  }
  
  return (
    <Container>
      <div className="py-3">
        <div className="row">
          <div className="col-3">
            <div className="input-group">
              <span className="input-group-text">Year</span>
              <select className="form-select" defaultValue={currYear} onChange={SetSelectedYear}>
                {Range(minYear, currYear).reverse().map(year => <option value={year} key={year}>{year}</option>)}
              </select>
            </div>
          </div>
          <div className="col-9">
            <div className="d-flex justify-content-end">
              {checkInWeeks.length === 0 ? <></> :
                <button className="btn btn-outline-secondary" onClick={ExportAsCsv}>Export as CSV</button>}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th colSpan="9"><h4>Weekly check-ins</h4></th>
            </tr>
            <tr>
              <th scope="col"><span>WEEK</span></th>
              <th scope="col"><span>TOTAL CHECK-INS</span></th>
              <th scope="col"><span>TOTAL POINTS</span></th>
              <th scope="col"><span>TOTAL HOURS</span></th>
            </tr>
          </thead>
          <tbody>
            {loading 
              ? <tr><td colSpan="9"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>
              :Array.from(checkInWeeks.keys()).slice((currentPage - 1) * pageSize, currentPage * pageSize).map(week => 
                <tr key={week}>
                  <td>{`${FormatDate(checkInWeeks.get(week).week_date_start)} - ${FormatDate(checkInWeeks.get(week).week_date_end)}`}</td>
                  <td>{checkInWeeks.get(week).check_in_count}</td>
                  <td>{checkInWeeks.get(week).total_points}</td>
                  <td>{(checkInWeeks.get(week).total_hours).toFixed(3)}</td>
                </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="3" className="text-right">TOTAL FOR YEAR:</th>
              <th className="text-center">{totalHours}</th>
            </tr>
          </tfoot>
        </table>
        <div id="pagination-cell">
          <ul className="pagination justify-content-center">
            <li className={currentPage === 1 ? "page-item disabled" : "page-item"} key={0}>
              <button className="page-link" aria-label="Previous" onClick={e => setCurrentPage(currentPage - 1)}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {
              Range(1, PageCount()).map(page => 
                <li className="page-item" key={page}>
                  <button className="page-link" onClick={e => setCurrentPage(page)}>{page}</button>
                </li>)
            }
            <li className={currentPage === PageCount() ? "page-item disabled" : "page-item"} key={-1}>
              <button className="page-link" aria-label="Next" onClick={e => setCurrentPage(currentPage + 1)}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
}